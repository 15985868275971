.container {
  margin: auto;
  padding: 1rem;
  h1 {
    text-align: left;
    padding: 0rem 2rem;
  }
}

.middle {
  display: grid;
  grid-template-columns: 25% 1fr;
  padding: 1rem;
  max-height: 400px;
  align-items: center;
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;

  img {
    width: 100%;
    height: 380px;
    object-fit: cover;
  }
  aside {
    /* color gold , italics, left justified */
    font-style: italic;
    text-align: left;
    padding: 1rem;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.author {
  /* horizontally align children */
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  padding: 1rem;
  /* height: 20%; */
  img {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 50%;
  }
  align-items: center;
}
.contributor {
  /* vertically align children */
  color: black;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.article {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .middle {
    grid-template-columns: 1fr;
  }
  .article {
    flex-direction: column;
    text-align: left;
  }
  .author {
    flex-direction: column;
    img {
      width: 200px;
      height: 200px;
      object-fit: cover;
    }
  }
  .contributor {
    align-items: center;
  }
}
