.container {
  display: grid;
  grid-template-rows: 40px 400px;
  gap: 1rem;
  box-sizing: border-box;
  max-width: 1200px;
}

.hero {
  /* display: grid; */
  display: flex;
  gap: 1rem;
  box-sizing: border-box;
  img {
    object-fit: cover;
    width: 75%;
    height: 100%;
  }
}

.layout {
  max-width: 1200px;
  margin: auto;
}

.breaking {
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}

.breaking span {
  background-color: black;
  color: white;
  padding: 3px;
  align-items: center;
  font-size: 0.8rem;
  /* all caps */
  text-transform: uppercase;
  /* border light grey; */
  border: 1px solid lightgrey;
}

.giveaway {
  justify-content: center;
  height: 100%;
  width: 25%;
  background-color: black;
  padding: 0 1rem 1rem 1rem;
  box-sizing: border-box;
}
.giveawayTitle {
  text-align: center;
  color: white;
  img {
    max-width: 130px;
    max-width: 130px;
  }
}

.giveawayPersona1 {
  box-shadow: 0 0 3px 3px #ff00ff;
}

.giveawayPersona2 {
  box-shadow: 0 0 3px 3px #00c3ff;
}
.giveawayPersona3 {
  box-shadow: 0 0 3px 3px #bbff00;
}
.podium {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.avatar {
  color: white;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 5px;
  background-color: cadetblue;
  border-radius: 2px;
  img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }
}

.subHero {
  display: grid;
  grid-template-columns: 75% 25%;
}
