
.navContainer {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem;
    background-color: #F1576F;
    max-height: 60px;
    box-sizing: border-box;
    max-width: 1200px;
    width: 100%;
    margin: auto;
    margin-top: 2rem;
    img {
      max-width: 150px;
      height: auto;
    }
    /* shaddow bottom    */
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.75);
}

.nav{
    gap:1rem;
    display: flex;
    a {
        color: white;
        text-decoration: none;
        font-size: 1rem;
        font-weight: bold;
          &:hover {
              color: #85d2da;
          }
      }
}
